html, body {
    position: fixed;
    margin:0;
    padding:0;
    height: 100%; 
    overflow: hidden; 
    width: 100%; 
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/assets/grid.png');
    background-repeat: repeat;
    background-size: cover;
    /* font-size: 16pt; */
    font-size: 2.2vh;
}
@media only screen and (min-width: 750px), screen and (orientation: landscape) {
    html {
        font-size: 2.6vh;
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
p, h1, h2, h3, h4, h5, label {
    font-family: 'Ludicrous';
}
h1 {
    /* font-size: 34pt; */
    /* font-size: 5em; */
    font-size: 2.4rem;
}
h2 {
    font-size: 1.8rem;
}
h3 {
    font-size: 1.3rem;
}
h4 {
    font-size: 1.2rem;
}
p {
    font-size: 1rem;
}
@font-face {
    font-family: 'Ludicrous';
    src: url('/assets/font/Ludicrous.eot');
    src: url('/assets/font/Ludicrous.eot?#iefix') format('embedded-opentype'),
        url('/assets/font/Ludicrous.woff2') format('woff2'),
        url('/assets/font/Ludicrous.woff') format('woff'),
        url('/assets/font/Ludicrous.ttf') format('truetype'),
        url('/assets/font/Ludicrous.svg#Ludicrous') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* @media only screen and (min-width: )  */
@media only screen and (min-width: 800px) {
    .fontText {
        display: none;
    }
    @font-face {
        font-family: 'Ludicrous';
        src: 'assets/Ludicrous.woff';
    }
}
/* @media only screen and (max-width: 800px) { */

/* } */



/* TODO: add basic 'preload' stuff here like the outline for mobile or the FAKE outline for desktop to represent faster loading */