@import '../assets/colors.scss';
#design_bg {
    position: absolute;
    height: 110vh;
    width: 110vw;
    margin-left: 50vw;
    margin-top: 50vh;
    background-size: cover;
    background-position: center;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0.95;
    &.default {
        display: none;
    }
}
#design_inner {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    padding: 145px 12.5vw 50px 12.5vw;
    @media screen and (max-height:740px) {
        padding: 110px 12vw 0px 15vw;
    }
    @media screen and (orientation:landscape) {
        padding: 20px 15vw 100px 20vw;
        flex-direction: row;
        align-items: center;
    }
}
#boxes {
    // flex: 0.7;
    // flex: auto;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: -40px;
    padding-right: 6px;
    margin-bottom: 15px;
    // flex-shrink: 1;
    @media screen and (orientation:landscape) {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        // align-items: center;
    }
    @media screen and (min-width:525px) {
        padding-right: 24px;
        flex: 0.7;
    }
}
#design_content {
    flex-shrink: 0;
    align-content: flex-end;
    background-color: #000000c2;
    // width: 100%;
    // width: 64.5vw;
    width: 74vw;
    @media screen and (max-height:900px) and (min-height:740px) { 
        width: 82vw;
        height: 25vw;
    }
    // width: auto;
    padding: 0;
    h4, h3, a {
        pointer-events: all;
        font-weight: 100;
        color: white;
    }
    &.default {
        display: none;
    }
    &.SS {
        background-color: rgba(255, 255, 255, 0.514)!important;
        h3, h4, a {
            color: black;
        }
    }
}
.box {
    border: 3px solid themeColor(secondary);
    margin-right: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 500ms, width 500ms;
    width: 21.5vw;
    height: 21.5vw;
    @media screen and (max-height:900px) and (min-height:740px) {
        // width: 21.5vw;
        // height: 21.5vw; 
        width: 25vw;
        height: 25vw;
    }
    @media screen and (min-height: 900px) {
        width: 20vw;
        height: 20vw;
    }
    // @media screen and (max-height:740px) {
    //     width: 21.5vw;
    //     height: 21.5vw;
    // }
    // @media screen and (max-height:740px) {
    //     width: 21.5vw;
    //     height: 21.5vw;
    // }
    @media screen and (orientation:landscape) {
        width: 16vh;
        height: 16vh;
    }
    img {
        width: 70%;
        height: 70%;
        clip-path: circle(50% at 50% 50%);
    }
    div {
        position: absolute;
        opacity: 0;
        width: 21.5vw;
        height: 21.5vw;
        transition: opacity 200ms;
        transition-timing-function: ease-in;
        @media screen and (max-height:900px) and (min-height:740px) {
            // width: 21.5vw;
            // height: 21.5vw; 
            width: 25vw;
            height: 25vw;
        }
        @media screen and (orientation:landscape) {
            width: 15vh;
            height: 15vh;
        }
    }
    // margin-bottom: -3px;
}
$boxColors: 500, 400, 300, 200, 100, 50;
@for $i from 0 through 5 {
    #box-#{$i} > div {
        @include background(secondary, nth($boxColors, $i + 1))
    };
}
#box-5 {
    flex-shrink: 0;
}
@each $col in map-keys($map: $designColors) {
    .box.#{$col} {
        $dCol: map-get($map: $designColors, $key: $col);
        border-color: map-get($map: $dCol, $key: accent);
    }
}
.row {
    margin-bottom: -3px;
    display: flex;
    margin-left: 12.5vw;
    transform: translateX(-3px);
    align-items: flex-start;
    flex-direction: row-reverse;
    width: 100%;
    @media screen and (min-height: 900px) {
        // margin-left: 22vw;
        max-width: 72vw;
    }
    @media screen and (orientation:landscape) {
        width: 30vw;
    }
}
%white {
    h2 {
        color: white;
    }
}
#content_header {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: right;
    align-content: flex-end;
    padding-right: 0.5em;
    h2 {
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: black;
        flex: 1;
        flex-basis: 1;
        word-wrap: break-word;
        // max-width: auto;
        // max-width: 100px;
    }
    // &.OpenGarage {
    //     @extend %white;
    // }
    // &.WMI {
    //     @extend %white;
    // }
    // &.WMI {
    //     @extend %white;
    // }
}
#design_content {
    flex-wrap: wrap;
    height: auto;
    margin: 0 -10px;
    // display: flex;
    // flex-direction: row;
    // max-width: 50%;
    // width: 50%;
    h3, h4, p {
        margin-block-start: 0;
        margin-block-end: 0.5em;
        pointer-events: all;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    // h3 {
    //     flex: 1;
    // }
    h4 {
        flex: 1;
    }
    @media screen and (orientation:landscape) {
        flex: 0.7;
        // margin-top: -40px;
        margin: 60px 5px;
        // display: flex;
        // flex-direction: row;
    }
    @media screen and (min-height:900px) {
        display: flex;
        flex-direction: row;
    }
}