html, body {
    position: fixed;
    margin:0;
    padding:0;
    height: 100%; 
    overflow: hidden; 
    width: 100%; 
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/assets/grid.png');
    background-repeat: repeat;
    background-size: cover;
    /* font-size: 16pt; */
    font-size: 2.2vh;
}
@media only screen and (min-width: 750px), screen and (orientation: landscape) {
    html {
        font-size: 2.6vh;
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
p, h1, h2, h3, h4, h5, label {
    font-family: 'Ludicrous';
}
h1 {
    /* font-size: 34pt; */
    /* font-size: 5em; */
    font-size: 2.4rem;
}
h2 {
    font-size: 1.8rem;
}
h3 {
    font-size: 1.3rem;
}
h4 {
    font-size: 1.2rem;
}
p {
    font-size: 1rem;
}
@font-face {
    font-family: 'Ludicrous';
    src: url('/assets/font/Ludicrous.eot');
    src: url('/assets/font/Ludicrous.eot?#iefix') format('embedded-opentype'),
        url('/assets/font/Ludicrous.woff2') format('woff2'),
        url('/assets/font/Ludicrous.woff') format('woff'),
        url('/assets/font/Ludicrous.ttf') format('truetype'),
        url('/assets/font/Ludicrous.svg#Ludicrous') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* @media only screen and (min-width: )  */
@media only screen and (min-width: 800px) {
    .fontText {
        display: none;
    }
    @font-face {
        font-family: 'Ludicrous';
        src: 'assets/Ludicrous.woff';
    }
}
/* @media only screen and (max-width: 800px) { */

/* } */



/* TODO: add basic 'preload' stuff here like the outline for mobile or the FAKE outline for desktop to represent faster loading */
canvas {
  display: block;
  z-index: 100;
  width: 100%;
  height: 100%; }

@media only screen and (max-width: 800) {
  #fontText {
    display: none; } }

/*
$primarydark: (
    50 : #e8edf3,
    100 : #c5d2e0,
    200 : #9eb4cc,
    300 : #7796b8,
    400 : #5a80a8,
    500 : #3d6999,
    600 : #376191,
    700 : #2f5686,
    800 : #274c7c,
    900 : #1a3b6b,
    A100 : #a6c7ff,
    A200 : #73a8ff,
    A400 : #4088ff,
    A700 : #2678ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$primary: (
    50 : #e3edf9,
    100 : #b9d3f0,
    200 : #8ab5e6,
    300 : #5b97db,
    400 : #3781d4,
    500 : #146bcc,
    600 : #1263c7,
    700 : #0e58c0,
    800 : #0b4eb9,
    900 : #063cad,
    A100 : #d8e2ff,
    A200 : #a5bdff,
    A400 : #7297ff,
    A700 : #5985ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondary: (
    50 : #ffede8,
    100 : #ffd3c6,
    200 : #ffb5a0,
    300 : #ff9779,
    400 : #ff815d,
    500 : #ff6b40,
    600 : #ff633a,
    700 : #ff5832,
    800 : #ff4e2a,
    900 : #ff3c1c,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd3cd,
    A700 : #ffbcb3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondarydark: (
    50 : #f9e5e3,
    100 : #f0bdb9,
    200 : #e6918a,
    300 : #db655b,
    400 : #d44437,
    500 : #cc2314,
    600 : #c71f12,
    700 : #c01a0e,
    800 : #b9150b,
    900 : #ad0c06,
    A100 : #ffd9d8,
    A200 : #ffa7a5,
    A400 : #ff7472,
    A700 : #ff5b59,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
*/
#overlay-scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  left: 0vw;
  width: 100vw;
  height: 100vh; }
  #overlay-scroller div {
    width: 300vw;
    height: 105vh; }

#underlay-scroller {
  overflow: show;
  width: 600vw;
  height: 200vh;
  margin-left: -250vw;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.inner {
  opacity: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column; }

.inner > h2 {
  position: absolute;
  top: 30px;
  left: 50px; }

.page {
  position: absolute;
  height: 200vh;
  width: 105vw;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  margin-left: 250vw; }

#design {
  background-color: #1C2833; }
  #design.default {
    background-color: #3d6999; }

#home {
  position: absolute;
  width: 100vw; }

#photography {
  background-color: #0000005e;
  margin-top: -5vh;
  height: 205vh;
  margin-left: 245vw; }

#photography > .inner > h2 {
  top: calc(5vh + 30px); }

/*
$primarydark: (
    50 : #e8edf3,
    100 : #c5d2e0,
    200 : #9eb4cc,
    300 : #7796b8,
    400 : #5a80a8,
    500 : #3d6999,
    600 : #376191,
    700 : #2f5686,
    800 : #274c7c,
    900 : #1a3b6b,
    A100 : #a6c7ff,
    A200 : #73a8ff,
    A400 : #4088ff,
    A700 : #2678ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$primary: (
    50 : #e3edf9,
    100 : #b9d3f0,
    200 : #8ab5e6,
    300 : #5b97db,
    400 : #3781d4,
    500 : #146bcc,
    600 : #1263c7,
    700 : #0e58c0,
    800 : #0b4eb9,
    900 : #063cad,
    A100 : #d8e2ff,
    A200 : #a5bdff,
    A400 : #7297ff,
    A700 : #5985ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondary: (
    50 : #ffede8,
    100 : #ffd3c6,
    200 : #ffb5a0,
    300 : #ff9779,
    400 : #ff815d,
    500 : #ff6b40,
    600 : #ff633a,
    700 : #ff5832,
    800 : #ff4e2a,
    900 : #ff3c1c,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd3cd,
    A700 : #ffbcb3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondarydark: (
    50 : #f9e5e3,
    100 : #f0bdb9,
    200 : #e6918a,
    300 : #db655b,
    400 : #d44437,
    500 : #cc2314,
    600 : #c71f12,
    700 : #c01a0e,
    800 : #b9150b,
    900 : #ad0c06,
    A100 : #ffd9d8,
    A200 : #ffa7a5,
    A400 : #ff7472,
    A700 : #ff5b59,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
*/
#design_bg {
  position: absolute;
  height: 110vh;
  width: 110vw;
  margin-left: 50vw;
  margin-top: 50vh;
  background-size: cover;
  background-position: center;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.95; }
  #design_bg.default {
    display: none; }

#design_inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 145px 12.5vw 50px 12.5vw; }
  @media screen and (max-height: 740px) {
    #design_inner {
      padding: 110px 12vw 0px 15vw; } }
  @media screen and (orientation: landscape) {
    #design_inner {
      padding: 20px 15vw 100px 20vw;
      flex-direction: row;
      align-items: center; } }

#boxes {
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: -40px;
  padding-right: 6px;
  margin-bottom: 15px; }
  @media screen and (orientation: landscape) {
    #boxes {
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center; } }
  @media screen and (min-width: 525px) {
    #boxes {
      padding-right: 24px;
      flex: 0.7 1; } }

#design_content {
  flex-shrink: 0;
  align-content: flex-end;
  background-color: #000000c2;
  width: 74vw;
  padding: 0; }
  @media screen and (max-height: 900px) and (min-height: 740px) {
    #design_content {
      width: 82vw;
      height: 25vw; } }
  #design_content h4, #design_content h3, #design_content a {
    pointer-events: all;
    font-weight: 100;
    color: white; }
  #design_content.default {
    display: none; }
  #design_content.SS {
    background-color: rgba(255, 255, 255, 0.514) !important; }
    #design_content.SS h3, #design_content.SS h4, #design_content.SS a {
      color: black; }

.box {
  border: 3px solid #ff6b40;
  margin-right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: height 500ms, width 500ms;
  transition: height 500ms, width 500ms;
  width: 21.5vw;
  height: 21.5vw; }
  @media screen and (max-height: 900px) and (min-height: 740px) {
    .box {
      width: 25vw;
      height: 25vw; } }
  @media screen and (min-height: 900px) {
    .box {
      width: 20vw;
      height: 20vw; } }
  @media screen and (orientation: landscape) {
    .box {
      width: 16vh;
      height: 16vh; } }
  .box img {
    width: 70%;
    height: 70%;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%); }
  .box div {
    position: absolute;
    opacity: 0;
    width: 21.5vw;
    height: 21.5vw;
    -webkit-transition: opacity 200ms;
    transition: opacity 200ms;
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in; }
    @media screen and (max-height: 900px) and (min-height: 740px) {
      .box div {
        width: 25vw;
        height: 25vw; } }
    @media screen and (orientation: landscape) {
      .box div {
        width: 15vh;
        height: 15vh; } }

#box-0 > div {
  background-color: #ff6b40; }

#box-1 > div {
  background-color: #ff815d; }

#box-2 > div {
  background-color: #ff9779; }

#box-3 > div {
  background-color: #ffb5a0; }

#box-4 > div {
  background-color: #ffd3c6; }

#box-5 > div {
  background-color: #ffede8; }

#box-5 {
  flex-shrink: 0; }

.box.default {
  border-color: getColor(secondary); }

.box.Kahani {
  border-color: #e91e63; }

.box.OpenGarage {
  border-color: #292670; }

.box.WMI {
  border-color: #C8504D; }

.box.DD {
  border-color: #E38C2D; }

.box.SS {
  border-color: #126872; }

.box.Sourcerer {
  border-color: #14D84B; }

.row {
  margin-bottom: -3px;
  display: flex;
  margin-left: 12.5vw;
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px);
  align-items: flex-start;
  flex-direction: row-reverse;
  width: 100%; }
  @media screen and (min-height: 900px) {
    .row {
      max-width: 72vw; } }
  @media screen and (orientation: landscape) {
    .row {
      width: 30vw; } }

#content_header {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: right;
  align-content: flex-end;
  padding-right: 0.5em; }
  #content_header h2 {
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    flex: 1 1;
    flex-basis: 1;
    word-wrap: break-word; }

#design_content {
  flex-wrap: wrap;
  height: auto;
  margin: 0 -10px; }
  #design_content h3, #design_content h4, #design_content p {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0.5em;
            margin-block-end: 0.5em;
    pointer-events: all;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0; }
  #design_content h4 {
    flex: 1 1; }
  @media screen and (orientation: landscape) {
    #design_content {
      flex: 0.7 1;
      margin: 60px 5px; } }
  @media screen and (min-height: 900px) {
    #design_content {
      display: flex;
      flex-direction: row; } }

/*
$primarydark: (
    50 : #e8edf3,
    100 : #c5d2e0,
    200 : #9eb4cc,
    300 : #7796b8,
    400 : #5a80a8,
    500 : #3d6999,
    600 : #376191,
    700 : #2f5686,
    800 : #274c7c,
    900 : #1a3b6b,
    A100 : #a6c7ff,
    A200 : #73a8ff,
    A400 : #4088ff,
    A700 : #2678ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$primary: (
    50 : #e3edf9,
    100 : #b9d3f0,
    200 : #8ab5e6,
    300 : #5b97db,
    400 : #3781d4,
    500 : #146bcc,
    600 : #1263c7,
    700 : #0e58c0,
    800 : #0b4eb9,
    900 : #063cad,
    A100 : #d8e2ff,
    A200 : #a5bdff,
    A400 : #7297ff,
    A700 : #5985ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondary: (
    50 : #ffede8,
    100 : #ffd3c6,
    200 : #ffb5a0,
    300 : #ff9779,
    400 : #ff815d,
    500 : #ff6b40,
    600 : #ff633a,
    700 : #ff5832,
    800 : #ff4e2a,
    900 : #ff3c1c,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd3cd,
    A700 : #ffbcb3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondarydark: (
    50 : #f9e5e3,
    100 : #f0bdb9,
    200 : #e6918a,
    300 : #db655b,
    400 : #d44437,
    500 : #cc2314,
    600 : #c71f12,
    700 : #c01a0e,
    800 : #b9150b,
    900 : #ad0c06,
    A100 : #ffd9d8,
    A200 : #ffa7a5,
    A400 : #ff7472,
    A700 : #ff5b59,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
*/
#menu_outer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; }

.default .menu {
  background-color: getColor(accent); }

.default #menu_nav {
  background-color: transparent;
  border-left-color: getColor(accent) !important; }

.default #wedged {
  border-top-color: getColor(secondary) !important; }

.Kahani .menu {
  background-color: #673ab7; }

.Kahani #menu_nav {
  background-color: transparent;
  border-left-color: #673ab7 !important; }

.Kahani #wedged {
  border-top-color: #e91e63 !important; }

.OpenGarage .menu {
  background-color: #91155e; }

.OpenGarage #menu_nav {
  background-color: transparent;
  border-left-color: #91155e !important; }

.OpenGarage #wedged {
  border-top-color: #292670 !important; }

.WMI .menu {
  background-color: #EFE4CF; }

.WMI #menu_nav {
  background-color: transparent;
  border-left-color: #EFE4CF !important; }

.WMI #wedged {
  border-top-color: #C8504D !important; }

.DD .menu {
  background-color: #9c27b0; }

.DD #menu_nav {
  background-color: transparent;
  border-left-color: #9c27b0 !important; }

.DD #wedged {
  border-top-color: #E38C2D !important; }

.SS .menu {
  background-color: #18C29C; }

.SS #menu_nav {
  background-color: transparent;
  border-left-color: #18C29C !important; }

.SS #wedged {
  border-top-color: #126872 !important; }

.Sourcerer .menu {
  background-color: #673ab7; }

.Sourcerer #menu_nav {
  background-color: transparent;
  border-left-color: #673ab7 !important; }

.Sourcerer #wedged {
  border-top-color: #14D84B !important; }

.menu {
  pointer-events: all;
  position: absolute;
  background-color: #00ffff; }

#fans_inner a {
  position: absolute;
  pointer-events: all;
  top: 0;
  -webkit-transition: right 500ms, top 500ms;
  transition: right 500ms, top 500ms; }
  @media screen and (min-width: 750px) {
    #fans_inner a img {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); } }

#fans_inner #fans_img-1 {
  display: none; }

#fans_inner #fans_img-5 {
  right: 125px;
  top: -5px; }

#fans_inner #fans_img-0 {
  right: 0px;
  top: 5px; }

#fans_inner #fans_img-3 {
  right: 95px;
  top: 80px; }

#fans_inner #fans_img-2 {
  right: 10px;
  top: 125px; }

#fans_inner #fans_img-4 {
  display: none;
  right: 15px;
  top: 140px; }

#fans_inner .hide_img {
  right: -110px !important;
  top: -110px !important; }

@media screen and (min-width: 750px) {
  #fans_inner #fans_img-5 {
    right: 170px;
    top: 15px; }
  #fans_inner #fans_img-0 {
    right: 15px;
    top: 25px; }
  #fans_inner #fans_img-3 {
    right: 130px;
    top: 128px; }
  #fans_inner #fans_img-2 {
    right: 22px;
    top: 190px; } }

#menu_fans {
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-transition: width 500ms, height 500ms;
  transition: width 500ms, height 500ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }
  @media screen and (min-width: 750px) {
    #menu_fans {
      -webkit-transform: translate(50%, -50%) scale(1.3);
              transform: translate(50%, -50%) scale(1.3); } }

#menu_nav {
  background-color: transparent;
  left: 0px;
  top: 50vh;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 100px solid;
  border-left-color: #00FFFF;
  border-right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: left 500ms, border 500ms;
  transition: left 500ms, border 500ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }
  @media screen and (min-width: 750px) {
    #menu_nav {
      border-top: 75px solid transparent;
      border-bottom: 75px solid transparent;
      border-left: 150px solid #00FFFF; } }

#menu_nav_inner {
  position: absolute;
  left: -195px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  opacity: 0; }
  @media screen and (min-width: 750px) {
    #menu_nav_inner {
      left: -240px; } }

#menu_nav_sub {
  position: absolute;
  left: -400px;
  top: 0;
  top: 50vh;
  border-top: 175px solid transparent;
  border-bottom: 175px solid transparent;
  border-right: 325px solid #00000098;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: left 500ms;
  transition: left 500ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }
  @media screen and (min-width: 750px) {
    #menu_nav_sub {
      border-top: 225px solid transparent;
      border-bottom: 225px solid transparent;
      border-right: 425px solid #00000098;
      left: -500px; } }

#menu_nav_sub_inner {
  position: absolute;
  width: 100%;
  left: 105px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: end; }
  @media screen and (min-width: 750px) {
    #menu_nav_sub_inner {
      left: 200px;
      margin-left: 10px;
      margin-top: 30px; }
      #menu_nav_sub_inner h2, #menu_nav_sub_inner h3, #menu_nav_sub_inner a {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        margin-top: 0px;
        word-wrap: none; }
      #menu_nav_sub_inner .menu_nav_sub_inner_text {
        margin-top: 0px;
        margin: 0;
        padding: 0; } }

#menu_nav_sub_inner > h2 {
  position: absolute;
  top: -80px;
  left: 10px;
  width: 200px;
  text-align: end;
  color: white;
  pointer-events: all; }

#menu_nav_sub_inner > div > h3 {
  text-align: end;
  width: 200px;
  color: lightgrey;
  pointer-events: all; }

#menu_scroll {
  width: 200px;
  height: 200px;
  border-radius: 50% 50% 0 0;
  bottom: 0;
  left: 50vw;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%); }
  @media screen and (min-width: 750px) {
    #menu_scroll {
      -webkit-transform: scale(1.5) translate(-37.5%, 37.5%);
              transform: scale(1.5) translate(-37.5%, 37.5%); } }

#wedge_clip {
  position: relative;
  height: 130px;
  bottom: 25px;
  width: 200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
          clip-path: polygon(0 0, 50% 100%, 100% 0);
  -webkit-transition: bottom 1s;
  transition: bottom 1s; }

#wedge_rotate {
  position: relative;
  height: 100%;
  width: 100%;
  left: 50%;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-transform: translateX(-50%) rotate(0deg);
          transform: translateX(-50%) rotate(0deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.wedge {
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  border-radius: 50%;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-top: 130px solid;
  border-top-color: #146bcc !important; }
  .wedge img {
    position: absolute;
    top: -110px;
    left: -25px; }

#wedgep {
  border-top-color: #5a80a8 !important;
  -webkit-transform: translate(-50%, 0) rotate(90deg);
          transform: translate(-50%, 0) rotate(90deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

#wedged {
  border-top-color: #ff6b40 !important;
  -webkit-transform: translate(-50%, 0) rotate(-90deg);
          transform: translate(-50%, 0) rotate(-90deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  z-index: -1; }

#menu_wedge {
  position: absolute;
  left: 50%;
  top: 100vh;
  width: 0;
  height: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50%;
  border-left: 30vh solid transparent;
  border-right: 30vh solid transparent;
  border-top: 60vh solid;
  -webkit-transition: top 300ms;
  transition: top 300ms;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

#menu_wedge > h4 {
  position: absolute;
  width: 30vh;
  top: -50vh;
  left: 0;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  text-align: center;
  pointer-events: all; }

.wp-active {
  border-top-color: #274c7c !important; }
  .wp-active h4 {
    color: #E8E8E8; }

.wh-active {
  border-top-color: #7796b8 !important; }
  .wh-active h4 {
    color: #050709; }

.wd-active {
  border-top-color: #1C2833 !important; }
  .wd-active h4 {
    color: #D4D4D4; }

.curvedarrow {
  position: relative;
  margin-left: 50px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-right: 18px solid black;
  -webkit-transform: translate(-100%, 100%) rotate(10deg);
          transform: translate(-100%, 100%) rotate(10deg); }

.curvedarrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 6px solid black;
  border-radius: 40px 0 0 0;
  top: -24px;
  left: -18px;
  width: 24px;
  height: 24px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

#home_outer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center; }

#forfans {
  margin-top: 70px;
  width: 100%;
  flex: 2 1;
  display: flex;
  flex-direction: row-reverse; }

#forfans > #arrow_fans {
  margin-right: 70px;
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

#myname {
  margin-top: 12vh;
  flex: 1 1;
  display: flex;
  justify-content: center; }

#myname > h3 {
  margin: 0; }

#home_outer > h1 {
  width: 100%;
  margin: 0;
  text-align: center;
  flex: 1 1; }

.text_bottom {
  position: absolute;
  top: calc(100vh - 150px); }

#text_scroll {
  width: 100%;
  text-align: center;
  top: calc(100vh - 200px);
  pointer-events: all; }

#text_scroll_arrow1 {
  left: 15vw;
  top: calc(100vh - 185px);
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

#text_scroll_arrow2 {
  right: 22vw;
  top: calc(100vh - 200px); }

#text_photo {
  left: 25px;
  border: 100px; }

#text_design {
  right: 25px;
  border: 100px; }

/*
$primarydark: (
    50 : #e8edf3,
    100 : #c5d2e0,
    200 : #9eb4cc,
    300 : #7796b8,
    400 : #5a80a8,
    500 : #3d6999,
    600 : #376191,
    700 : #2f5686,
    800 : #274c7c,
    900 : #1a3b6b,
    A100 : #a6c7ff,
    A200 : #73a8ff,
    A400 : #4088ff,
    A700 : #2678ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$primary: (
    50 : #e3edf9,
    100 : #b9d3f0,
    200 : #8ab5e6,
    300 : #5b97db,
    400 : #3781d4,
    500 : #146bcc,
    600 : #1263c7,
    700 : #0e58c0,
    800 : #0b4eb9,
    900 : #063cad,
    A100 : #d8e2ff,
    A200 : #a5bdff,
    A400 : #7297ff,
    A700 : #5985ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondary: (
    50 : #ffede8,
    100 : #ffd3c6,
    200 : #ffb5a0,
    300 : #ff9779,
    400 : #ff815d,
    500 : #ff6b40,
    600 : #ff633a,
    700 : #ff5832,
    800 : #ff4e2a,
    900 : #ff3c1c,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd3cd,
    A700 : #ffbcb3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondarydark: (
    50 : #f9e5e3,
    100 : #f0bdb9,
    200 : #e6918a,
    300 : #db655b,
    400 : #d44437,
    500 : #cc2314,
    600 : #c71f12,
    700 : #c01a0e,
    800 : #b9150b,
    900 : #ad0c06,
    A100 : #ffd9d8,
    A200 : #ffa7a5,
    A400 : #ff7472,
    A700 : #ff5b59,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
*/
#photography_outer {
  display: flex;
  flex-direction: column;
  padding: 0 12.5vw; }
  #photography_outer h2 {
    position: absolute;
    left: 50px;
    top: 50px; }

#photography_inner {
  margin-left: 43vw;
  margin-top: 55vh;
  width: 100%;
  -webkit-transform: translate(-50%, -50%) skewX(-5deg);
          transform: translate(-50%, -50%) skewX(-5deg); }

.box_photo {
  border: 5px solid #3d6999;
  height: 20vh;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column; }

.section_photo_head {
  align-content: flex-start;
  margin: -5px -5px;
  width: auto;
  height: 20%;
  background-color: #3781d4; }

.section_photo_body {
  flex-grow: 1;
  align-content: center;
  display: flex;
  align-items: center;
  flex-direction: row; }
  .section_photo_body h3 {
    width: 100%;
    text-align: center;
    color: #4F5A65; }

.section_photo_foot {
  align-content: flex-end;
  margin: -5px -5px;
  width: auto;
  height: 20%;
  background-color: #376191; }

