canvas {
    display: block;
    z-index: 100;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 800) {
    #fontText {
        display: none;
    }
}