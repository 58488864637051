@import '../assets//colors.scss';
#menu_outer {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
};
@each $col in map-keys($map: $designColors) {
    $dCol: map-get($map: $designColors, $key: $col);
    .#{$col} {
        .menu {
            background-color: map-get($map: $dCol, $key: primary);
        }
        #menu_nav {
            background-color: transparent;
            border-left-color: map-get($map: $dCol, $key: primary)!important;
        }
        #wedged {
            border-top-color: map-get($map: $dCol, $key: accent)!important;
        }
    }
}
.menu {
    pointer-events: all;
    position: absolute;
    @include background();
}
#fans_inner {
    a {
        position: absolute;
        pointer-events: all;
        // right: 50px;
        top: 0;
        transition: right 500ms, top 500ms;
        // scale: 4;
        @media screen and (min-width: 750px) {
            img {
                transform: scale(1.3);
            }
        }
    }

    #fans_img-1{
        display: none;
    }

    #fans_img-5 {
        right: 125px;
        top: -5px;
    }
    #fans_img-0 {
        right: 0px;
        top: 5px;
    }

    #fans_img-3 {
        right: 95px;
        top: 80px;
    }

    #fans_img-2 {
        right: 10px;
        top: 125px;
    }
    
    #fans_img-4 {
        display: none;
        right: 15px;
        top: 140px;
    }
    .hide_img {
        right: -110px !important;
        top: -110px !important;
    }
    @media screen and (min-width: 750px) {
        #fans_img-5 {
            right: 170px;
            top: 15px;
        }
        #fans_img-0 {
            right: 15px;
            top: 25px;
        }

        #fans_img-3 {
            right: 130px;
            top: 128px;
        }

        #fans_img-2 {
            right: 22px;
            top: 190px;
        }
    }
    // right: 50px;
}
    // @for $i from 0 through 5 {
    //     #fans_img-#{$i} {
    //         right: $i * 50px; 
    //     }
    // }
#menu_fans {
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    transition: width 500ms, height 500ms;
    transition-timing-function: ease-in-out;
    @media screen and (min-width: 750px) {
        // img {
            transform: translate(50%, -50%) scale(1.3);
        // }
    }
}
#menu_nav {
    background-color: transparent;
    left: 0px;
    top: 50vh;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 100px solid;
    border-left-color: #00FFFF;
    border-right: 0px;
    transform: translateY(-50%);
    transition: left 500ms, border 500ms;
    transition-timing-function: ease-in-out;
    @media screen and (min-width: 750px) {
        border-top: 75px solid transparent;
        border-bottom: 75px solid transparent;
        border-left: 150px solid #00FFFF;
    }
}
#menu_nav_inner {
    position: absolute;
    // display: flex;
    left: -195px;
    top: 0;
    transform: translateY(-50%);
    transition: opacity 500ms;
    transition-timing-function: ease-out;
    opacity: 0;
    @media screen and (min-width: 750px) {
        left: -240px;
    }
}
#menu_nav_sub {
    position: absolute;
    left: -400px;
    top: 0;
    top: 50vh;
    border-top: 175px solid transparent;
    border-bottom: 175px solid transparent;
    border-right: 325px solid #00000098;
    transform: translateY(-50%);
    transition: left 500ms;
    transition-timing-function: ease-in-out;
    @media screen and (min-width: 750px) {
        border-top: 225px solid transparent;
        border-bottom: 225px solid transparent;
        border-right: 425px solid #00000098;
        left: -500px;
        // transform: translate(-30%, -50%);
    }
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#menu_nav_sub_inner {
    position: absolute;
    width: 100%;
    left: 105px;
    top: 0;
    transform: translateY(-50%);
    text-align: end;
    @media screen and (min-width: 750px) {
        left: 200px;
        margin-left: 10px;
        margin-top: 30px;
        h2, h3, a {
            transform: scale(0.9);
            margin-top: 0px;
            word-wrap: none;
        }
        .menu_nav_sub_inner_text {
            // margin-left: 20px;
            margin-top: 0px;
            margin: 0;
            padding: 0;
        }
    }
}
#menu_nav_sub_inner > h2 {
    position: absolute;
    top: -80px;
    left: 10px;
    width: 200px;
    text-align: end;
    color: white;
    pointer-events: all;
} 
#menu_nav_sub_inner > div > h3 {
    text-align: end;
    width: 200px;
    color: lightgrey;
    pointer-events: all;
}
#menu_scroll {
    width: 200px;
    height: 200px;
    border-radius: 50% 50% 0 0;
    bottom: 0;
    left: 50vw;
    transform: translate(-50%, 50%);
    @media screen and (min-width: 750px) {
        transform: scale(1.5) translate(-37.5%, 37.5%);
    }
}
#wedge_clip {
    position: relative;
    height: 130px;
    bottom: 25px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    transition: bottom 1s;
}
#wedge_rotate {
    position: relative;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translatex(-50%);
    transform: translateX(-50%) rotate(0deg);
    transform-origin: 50% 100%;
}
.wedge {
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, 0%);
    border-radius: 50%;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
    border-top: 130px solid;
    @include border-top(primary);
    img {
        position: absolute;
        top: -110px;
        left: -25px;
    }
}
#wedgep {
    @include border-top(primarydark, 400);
    transform: translate(-50%, 0) rotate(90deg);
    transform-origin: 50% 100%;
}
#wedged {
    @include border-top(secondary);
    transform: translate(-50%, 0) rotate(-90deg);
    transform-origin: 50% 100%;
    z-index: -1;
}
#menu_wedge {
    position: absolute;
    left: 50%;
    top: 100vh;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-radius: 50%;
    border-left: 30vh solid transparent;
    border-right: 30vh solid transparent;
    border-top: 60vh solid;
    // @include border-top(primary);
    transition: top 300ms;
    transition-timing-function: ease-out;
}
#menu_wedge > h4 {
    position: absolute;
    width: 30vh;
    top: -50vh;
    left: 0;
    transform: translate(-50%);
    text-align: center;
    pointer-events: all;
}
.wp-active {
    @include border-top(primarydark, 800);
    h4 {
        color: themeColor(primary, contrast, 300);
    }
    // @include border-top(secondarydark, 200);

}
.wh-active {
    @include border-top(primarydark, 300);
    h4 {
        color: themeColor(primary, contrast, 100);
    }
}
.wd-active {
    @include border-top(accent, contrast, 600);
    h4 {
        color: themeColor(primary, contrast, 700)
    }
}