@import '../assets/colors.scss';
#photography_outer {
    display: flex;
    flex-direction: column;
    padding: 0 12.5vw;
    h2 {
        position: absolute;
        left: 50px;
        top: 50px;
    }
}
#photography_inner {
    margin-left: 43vw;
    margin-top: 55vh;
    width: 100%;
    transform: translate(-50%, -50%) skewX(-5deg);
}
.box_photo {
    border: 5px solid themeColor(primarydark);
    height: 20vh;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}

.section_photo_head {
    align-content: flex-start;
    margin: -5px -5px;
    width: auto;
    height: 20%;
    @include background(primary, 400);
    // background-color: #063CAD;
}
.section_photo_body {
    flex-grow: 1;
    align-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    h3 {
        width: 100%;
        text-align: center;
        color: themeColor(accent, contrast, A100);
    }
}
.section_photo_foot {
    align-content: flex-end;
    margin: -5px -5px;
    width: auto;
    height: 20%;
    @include background(primarydark, 600);
}